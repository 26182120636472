const faqs = [
  {
    question: "Cobertura médica",
    answer: (
      <div>
        <p>

        En caso de que sufras una enfermedad o un accidente durante tu viaje a Brasil, un seguro de viaje te cubrirá los gastos médicos. Esto es especialmente importante si no tienes seguro médico internacional. Los costos de atención médica en Brasil pueden ser muy elevados, y sin un seguro, podrías verte obligado a pagar facturas muy caras de tu propio bolsillo.

</p>
      </div>
    ),
  },
  {
    question: "Cancelación de viaje ",
    answer: (
      <div>
        <p>Otra ventaja de contar con un seguro de viaje Brasil es que te protege en caso de que tengas que cancelar tu viaje. Los motivos de cancelación pueden variar, desde enfermedades inesperadas hasta situaciones personales o laborales que requieren que pospongas tu viaje. Con un seguro de viaje, puedes recuperar una parte o todo el costo de tu viaje, según los términos y condiciones de tu póliza.

 </p> 

      </div>
    ),
  },
  {
    question: "Retrasos en vuelos",
    answer: (
      <div>
        <p>
        Los retrasos en los vuelos pueden ser una molestia, pero con un seguro de viaje, puedes estar seguro de que te protegerán en caso de que se produzca un retraso significativo en tu vuelo. Un seguro de viaje Brasil puede cubrir los gastos adicionales, como alojamiento, transporte y comida, que puedan surgir si tu vuelo se retrasa o se cancela.
        </p> 
      </div>
    ),
  },
  {
    question: "Pérdida o robo de equipaje",
    answer: (
      <div>
        <p>
        Otra situación desafortunada que puede arruinar tus vacaciones es la pérdida o el robo de tu equipaje. Con un seguro de viaje, puedes estar tranquilo sabiendo que te cubrirán los gastos de reemplazar tus pertenencias en caso de que se pierdan o sean robadas.
        </p> 
      </div>
    ),
  },
  {
    question: "Asistencia en caso de emergencia",
    answer: (
      <div>
        <p>
        Si sufres una emergencia en Brasil, como un accidente automovilístico o un problema legal, tu seguro de viaje puede proporcionarte asistencia inmediata. Algunos seguros de viaje incluso ofrecen asistencia en caso de que necesites un abogado o un intérprete.
        </p> 
      </div>
    ),
  },

];

export default faqs;
